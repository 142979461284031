
import './App.css';

import Home from './pages/home';

import PDFRendering from './pages/pdfRendering';
import ExcelRendering from './pages/excelRendering';

import ColorLabelIdomyaPage from './pages/colorLabelIdomyaFactory';
import ColorLabelJardiktJardiPage from './pages/colorLabelJardiktJardi';
import ColorLabelJardiktAnimaPage from './pages/colorLabelJardiktAnima';
import ColorLabelInkazenPage from './pages/colorLabelInkazen';
import ColorLabelIdomyaEssPage from './pages/colorLabelIdomiyaEss';

import ErrorPage from './pages/error'

import {
  BrowserRouter ,
  Routes,
  Route
} from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
    <Routes>

        <Route path="pdf" element={<PDFRendering />}/>
        <Route path="colorLabelIdomya" element={<ColorLabelIdomyaPage />}/>
        <Route path="colorLabelJardiktJardi" element={<ColorLabelJardiktJardiPage />}/>
        <Route path="colorLabelJardiktAnima" element={<ColorLabelJardiktAnimaPage />}/>
        <Route path="ColorLabelIdomyaEss" element={<ColorLabelIdomyaEssPage />}/>
        <Route path="colorLabelInkazen" element={<ColorLabelInkazenPage />}/>
        <Route path="excel" element={<ExcelRendering />}/>
        <Route index element={<Home />} />
        <Route path='*' element={<ErrorPage />} />

    </Routes>
  </BrowserRouter>
  )
}



export default App;
