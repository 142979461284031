
import './home.css';

import { useLocation } from "react-router-dom";


// Mettre le nom de la fonction pour presenter la matrice
function MatriceButton(props) {
	console.log(props)
	if (props.documentType === "declinaison") {
		return (
			<button
				className="redButton"
				onClick={props.openOnNewTabPDFfr}>
				Exportation des Matrices
			</button>
		)
	}
	else if (props.documentType === "bundle") {
		return (
			<button
				className="redButton"
				onClick={props.openOnNewTabPDFfr}>
				Exportation de la Matrice
			</button>
		)
	}
}

// Renvoie la liste des actions en fonction du type de données
function ActionList(props) {
	console.log(props)
	if (props.documentType === "declinaison") {
		return (
			<div className="homebuttonsContainer">
				<div className="homePageButtonsPdf">
					<button
						className="blueButton"
						onClick={props.openOnNewTabPDFfr}>
						Fiche Produit PDF Fr
					</button>
					<button
						className="redButton"
						onClick={props.openOnNewTabPDFEn}>
						Fiche Produit PDF En
					</button>
					<button
						className="redButton"
						onClick={props.openOnNewTabMatrice}>
						Export des Matrices
					</button>
				</div>
				{props.showColorLabel == "1" && (<div className="homePageButtonsLabel">
					<button
						className="blueButton"
						onClick={props.openOnNewTabPDFColorLabelIdomya}>
						CL Idomya Factory
					</button>
					<button
						className="blueButton"
						onClick={props.openOnNewTabPDFColorLabelJardiktJardi}>
						CL Jardikt Jardin
					</button>
					<button
						className="blueButton"
						onClick={props.openOnNewTabPDFColorLabelJardiktAnima}>
						CL Jardikt Animalerie
					</button>
				</div>)}
				{props.showColorLabel == "1" && (<div className="homePageButtonsLabel">
					<button
						className="blueButton"
						onClick={props.openOnNewTabPDFColorLabelIdomyaEss}>
						CL Idomya Essentials
					</button>
					<button
					className="blueButton"
					onClick={props.openOnNewTabPDFColorLabelInkazen}>
					CL Inkazen
				</button>
			</div>)}

			</div>

		)
	}
	else if (props.documentType === "bundle") {
		return (
			<div className="homebuttonsContainer">
				<button
					className="redButton"
					onClick={props.openOnNewTabMatrice}>
					Export de la Matrice
				</button>
			</div>

		)
	}
}

function Home() {

	const search = useLocation().search;
	const ids = new URLSearchParams(search).get('ids');
	const token = new URLSearchParams(search).get('token');
	const documentType = new URLSearchParams(search).get('documentType')
	const showColorLabel = new URLSearchParams(search).get('accessColorLabel');

	function openOnNewTabPDFfr() {
		window.open('./pdf?locale=fr_FR&ids=' + ids + '&token=' + token)
	}
	function openOnNewTabPDFEn() {
		window.open('./pdf?locale=en_US&ids=' + ids + '&token=' + token)
	}
	function openOnNewTabMatrice() {
		window.open('./excel?&ids=' + ids + '&token=' + token + '&documentType=' + documentType)
	}

	function openOnNewTabPDFColorLabelIdomya() {
		window.open('./colorLabelIdomya?locale=fr_FR&ids=' + ids + '&token=' + token)
	}

	function openOnNewTabPDFColorLabelJardiktJardi() {
		window.open('./colorLabelJardiktJardi?locale=fr_FR&ids=' + ids + '&token=' + token)
	}

	function openOnNewTabPDFColorLabelJardiktAnima() {
		window.open('./colorLabelJardiktAnima?locale=fr_FR&ids=' + ids + '&token=' + token)
	}

	function openOnNewTabPDFColorLabelIdomyaEss() {
		window.open('./	ColorLabelIdomyaEss?locale=fr_FR&ids=' + ids + '&token=' + token)
	}

	function openOnNewTabPDFColorLabelInkazen() {
		window.open('./	ColorLabelInkazen?locale=fr_FR&ids=' + ids + '&token=' + token)
	}


	/*return (
		<div className="homecontainer">
			<div className="homebuttonsContainer">
				<button
						className="blueButton"
						onClick={openOnNewTabPDFfr}>
						Fiche Produit PDF Fr
				</button>
				<button
						className="redButton"
						onClick={openOnNewTabPDFEn}>
						Fiche Produit PDF En
				</button>
				<MatriceButton documentType={ documentType } openOnNewTabPDFfr={openOnNewTabMatrice} />
				</div>
	  </div>
	)*/

	return (
		<div className="homecontainer">
			<div className="homebuttonsContainer">
				<ActionList documentType={documentType} openOnNewTabPDFColorLabelJardiktJardi={openOnNewTabPDFColorLabelJardiktJardi} openOnNewTabPDFColorLabelInkazen={openOnNewTabPDFColorLabelInkazen} openOnNewTabPDFColorLabelIdomyaEss={openOnNewTabPDFColorLabelIdomyaEss} openOnNewTabPDFColorLabelJardiktAnima={openOnNewTabPDFColorLabelJardiktAnima} openOnNewTabMatrice={openOnNewTabMatrice} openOnNewTabPDFfr={openOnNewTabPDFfr} openOnNewTabPDFEn={openOnNewTabPDFEn} openOnNewTabPDFColorLabelIdomya={openOnNewTabPDFColorLabelIdomya} showColorLabel={showColorLabel} />
			</div>
		</div>
	)

}


export default Home;