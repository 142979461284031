import './rendering.css';
import { useEffect, useState } from "react"
import {useLocation} from "react-router-dom";
import html2pdf from 'html2pdf.js';
const axios = require("axios").default;

function PDFRendering() {

	const [isLoaded, setIsLoaded] = useState(false);
	const [pdfData, setPdfData] = useState(null);
	const search = useLocation().search;
	

	function PrintPdf() {
		window.print()

    /*var listPageHtml = Array.from(document.querySelectorAll('.pdfcontainer'))


      const today =  new Date().toLocaleString("fr-FR");
			var options = {
				filename: "Sélection Produits Sourcidys " + today +".pdf",
				html2canvas: {
					useCORS: true,
					scale: 2,
				},
				jsPDF: {
					format:"a4",
					unit:"mm",
          orientation: 'landscape'
				}
			}

      let worker = html2pdf()
    .set(options)
    .from(listPageHtml[0])

  if (listPageHtml.length > 1) {
    worker = worker.toPdf() // worker is now a jsPDF instance

    // add each element/page individually to the PDF render process
    listPageHtml.slice(1).forEach((element, index) => {
      worker = worker
        .get('pdf')
        .then(pdf => {
          console.log("page Num",index)
          pdf.addPage()
        })
        .from(element)
        .toContainer()
        .toCanvas()
        .toPdf()
      })
    }

    worker = worker.save()
			//html2pdf(data,options);*/
	}

	useEffect(() => {
		
 		const currentIds = new URLSearchParams(search).get('ids');
		const currentToken = new URLSearchParams(search).get('token');
		const currenLocale = new URLSearchParams(search).get('locale');
		const today =  new Date().toLocaleString("fr-FR");
		document.title = "Sélection Produits Sourcidys " + today

		axios.get("/api/pdf",{params: {token: encodeURIComponent(currentToken), ids:currentIds, locale: currenLocale}}).then(resp => {
			if (resp.status === 200) {
				console.log("success",resp.data)
				setPdfData(resp.data)
				
			} else {
				console.log("error",resp.data)
			}
			setIsLoaded(true)
		}).catch(error => {
			console.log(error)
      setIsLoaded(true)
		})



	  }, [])

	
	
	let dataPdf;

	
	if (isLoaded) {
		if (pdfData != null) {
			let listStructData =  []

      // ajout page de garde
      listStructData.push(<PageDeGarde /> )

      const dataLength =pdfData.docIds.length
			for (let index = 0; index < dataLength; index++) {
				const element = pdfData.docIds[index];
				let last = false;
				if (index === dataLength -1) {
					console.log("last")
					last = true;
				} 
				listStructData.push(<OnePagePrintRendering data ={element} lastElement ={last}/> )
				
			}

      // ajout des pages de clôture
      listStructData.push(<PagesDeClotureContact /> )
      listStructData.push(<PagesDeClotureFinale /> )

			dataPdf = <div><div class="noshowPdf" style={styleSheetProductPage.marginTopPrint}></div><div class="resultContainer">{listStructData}</div> </div>
		} else {
			dataPdf = <div className='pdfLoader'> Erreur dans la récupération des données</div>
		}
	} else {
		dataPdf =<div className='pdfLoader'>Récupération des données...<br/>Veuillez Patienter</div>
	}

	return (
		<div style={{margin: 0}}>
      <style>
        {`@media print {
	          .pdfnextPage {
	            page-break-after: always;
	          }
  
	          .pdfbuttonContainer {
	            display: none !important;
	          }

            .noshowPdf {
              display: none !important;
            }
  
	          .pdfcontainerBorder {
	            border-bottom: 0px solid black !important;
	          }
  
	          .pdfcontainer {
	            top: 0px !important;
	          }
  
	          @page {
	            margin: 0;
	            size: A4 landscape;
	          }
  
	          body {
	            -webkit-print-color-adjust: exact;
	          color-adjust: exact;
	          }
          }`
        }
      </style>
			<div class="pdfbuttonContainer">
      			<button class="pdfprintPDFButton" onClick={PrintPdf}>
        			Print PDF
      			</button>
    		</div>
        
        {dataPdf}
        
			
			
		</div>
		
	)
}

function OnePagePrintRendering(props) {
	const sheetData = props.data
	const isLast = props.lastElement


	return(
		<div className={`pdfcontainer pdfcontainerBorder pdfnextPage`}>
        <img style={styleSheetProductPage.pdfbackground} src="/assets/images/links/Forme_dessous.png" />
        <img style={styleSheetProductPage.pdfbackground} src="/assets/images/links/Formes.png" />
        <img style={styleSheetProductPage.pdfavantageProduit} src="/assets/images/links/Avantage_produit.png" />
        <img style={styleSheetProductPage.pdflogo} src="/assets/images/logos/LOGO_SOURCIDYS_300DPI_RVB.png" />
        <div style={styleSheetProductPage.pdfnameContainer}>
          <h1 style={styleSheetProductPage.pdfname}>{sheetData.declinaisonName}</h1>
        </div>
        <div style={styleSheetProductPage.pdfinfoContainer}>
          <div style={styleSheetProductPage.pdfinfo}>
            <h3><span>Réf :</span> {sheetData.docId}</h3>
            <h3><span>EAN :</span> {sheetData.declinaisonEan}</h3>
            <h3><span>Taux douane :</span> {sheetData.declinaisonGroupeDouanes}%</h3>
            { sheetData.declinaisonEcoMobilier &&
              <h3><span>Eco-mob :</span> {sheetData.declinaisonEcoMobilier}</h3>
            }
            {sheetData.declinaisonDeee &&
              <h3><span>DEEE :</span> {sheetData.declinaisonDeee}</h3>
            }
          </div>
        </div>
        <div style={styleSheetProductPage.pdfplusProduitContainer}>
          <div class="pdfplusProduit" dangerouslySetInnerHTML={{__html:sheetData.declinaisonLesPlusProduit}}></div>
        </div>
        {sheetData.locale === "en_US" ?
        <h2 style={styleSheetProductPage.pdfdescriptifMarketingTitle}>MARKETING <span>DESCRIPTION</span></h2>
        :<h2 style={styleSheetProductPage.pdfdescriptifMarketingTitle}>DESCRIPTIF <span>MARKETING</span></h2>
        }
        <div style={styleSheetProductPage.pdfdescriptifMarketingContentContainer}><div style={styleSheetProductPage.pdfdescriptifMarketingContent} dangerouslySetInnerHTML={{__html:sheetData.declinaisonDescriptifMarketing}}></div></div>
        {sheetData.locale === "en_US" ?
        <h2 style={styleSheetProductPage.pdfdescriptifTechniqueTitle}>TECHNICAL <span>CHARACTERISTICS</span></h2>
        :
        <h2 style={styleSheetProductPage.pdfdescriptifTechniqueTitle}>CARACTÉRISTIQUES <span>TECHNIQUES</span></h2>
        }
        <div style={styleSheetProductPage.pdfdescriptifTechniqueContentContainer}><div style={styleSheetProductPage.pdfdescriptifTechniqueContent} dangerouslySetInnerHTML={{__html:sheetData.declinaisonDescriptifTechnique}}></div></div>
        {sheetData.img1 &&
          <img style={styleSheetProductPage.pdfimg1} src={sheetData.img1} />
        }
        {sheetData.img2 &&
          <img style={styleSheetProductPage.pdfimg2} src={sheetData.img2} />
        }
        {sheetData.img3 &&
          <img style={styleSheetProductPage.pdfimg3} src={sheetData.img3} />
        }
        <div style={styleSheetProductPage.pdfvariantsTableContainer}>
          <table style={styleSheetProductPage.pdfvariantsTable}>
            <tr style={styleSheetProductPage.pdfrowStyle}>
              <th style={styleSheetProductPage.pdftableHeaderLeft} scope="row">Référence carton</th>
              {sheetData.variants.map((variant) => 
				<td style={styleSheetProductPage.pdfcolumnStyle} scope="col"><div>{variant.fields.sku_reference_carton}</div></td>
			  )}
			  
            </tr>
            <tr>
              <th style={styleSheetProductPage.pdftableHeaderLeft} scope="row">L x l x H carton (cm)</th>
              {sheetData.variants.map((variant) => 
                <td style={styleSheetProductPage.pdfcolumnStyle} scope="col"><div>{variant.fields.sku_longueur_carton} x {variant.fields.sku_largeur_carton} x {variant.fields.sku_hauteur_carton}</div></td>
				)}
            </tr>
            <tr style={styleSheetProductPage.pdfrowStyle}>
              <th style={styleSheetProductPage.pdftableHeaderLeft} scope="row">Poids brut carton (kg)</th>
              {sheetData.variants.map((variant) => 
                <td style={styleSheetProductPage.pdfcolumnStyle} scope="col"><div>{variant.fields.sku_poids_carton_individuel_brut}</div></td>
				)}
            </tr>
            <tr>
              <th style={styleSheetProductPage.pdftableHeaderLeft} scope="row">Poids net carton (kg)</th>
              {sheetData.variants.map((variant) => 
                <td style={styleSheetProductPage.pdfcolumnStyle} scope="col"><div>{variant.fields.sku_poids_carton_individuel}</div></td>
				)}
            </tr>
            <tr style={styleSheetProductPage.pdfrowStyle}>
              <th style={styleSheetProductPage.pdftableHeaderLeft} scope="row">PCB - Quantité par master</th>
              {sheetData.variants.map((variant) => 
                <td style={styleSheetProductPage.pdfcolumnStyle} scope="col"><div>{variant.fields.sku_quantite_par_carton_export}</div></td>
				)}
            </tr>
            <tr>
              <th style={styleSheetProductPage.pdftableHeaderLeft} scope="row">Quantité palette Stock</th>
              {sheetData.variants.map((variant) => 
                <td style={styleSheetProductPage.pdfcolumnStyle} scope="col"><div>{variant.fields.sku_quantite_par_palette}</div></td>
				)}
            </tr>
            <tr style={styleSheetProductPage.pdfrowStyle}>
              <th style={styleSheetProductPage.pdftableHeaderLeft} scope="row">Quantité palette GD</th>
              {sheetData.variants.map((variant) => 
                <td style={styleSheetProductPage.pdfcolumnStyle} scope="col"><div>{variant.fields.sku_quantite_par_palette_GD}</div></td>
				)}
            </tr>
          </table>
        </div>
      </div>
	)
}

function PageDeGarde(props) {
  return (
    <div className={`pdfcontainer pdfcontainerBorder pdfnextPage`}>
      <img style={styleSheetProductPage.pdfbackgroundGardCloture}  src="/assets/images/pagesDeGarde/gardeCatalog.PNG" />
      <p style={{position:"absolute", top:"620px",left:"175px",color: "rgb(225, 8, 28)", "font-family": "Finador Light", "font-size":"18px"}}>{new Date().toLocaleString("fr-FR").split(" ")[0]}</p>
    </div>
  )
}

function PagesDeClotureContact(props) {
  return (
    <div className={`pdfcontainer pdfcontainerBorder pdfnextPage`}>
      <img style={styleSheetProductPage.pdfbackgroundGardCloture}  src="/assets/images/pageClotures/clotureContact.PNG" />
    </div>
  )  
}

function PagesDeClotureFinale(props) {
  return (
    <div className={`pdfcontainer pdfcontainerBorder`}>
      <img style={styleSheetProductPage.pdfbackgroundGardCloture} src="/assets/images/pageClotures/clotureFinale.PNG" />
    </div>
  ) 
}

const styleSheetProductPage = {
  pdfbackground: {
    position: "absolute",
    height: "793px",
    "z-index": "-1",
  },
  pdfbackgroundGardCloture: {
    position: "absolute",
    height: "793px",
    "z-index": "-1",
    width:"inherit",
  },
  pdfavantageProduit: {
    position: "absolute",
    top: "395px",
    left: "590px",
    width: "250px",
  },
  pdflogo: {
    top: "625px",
    left: "-75px",
    position: "absolute",
    width: "250px",
    transform: "rotate(-90deg)",
    "z-index": "1",
  },
  pdfnameContainer: {
    position: "absolute",
    left: "750px",
    top: "130px",
    width: "350px",
    height: "125px",
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
  },
  pdfname: {
	  color: "rgb(225, 8, 28)",
	  "font-family": "Finador Light",
  },
  pdfinfoContainer: {
    position: "absolute",
    width: "125px",
    height: "85px",
    top: "30px",
    left: "960px",
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
  },
  pdfinfo: {
    display: "flex",
    "flex-direction": "column",
    "text-align": "end",
  },
  pdfplusProduitContainer: {
    position: "absolute",
    left: "635px",
    top: "445px",
    width: "180px",
    height: "125px",
    display: "flex",
    "flex-direction": "row",
    "justify-content": "flex-start",
    "align-items": "center",
  },
  pdfimg1: {
    position: "absolute",
    "max-width": "660px",
    "min-width": "100px",
    height: "410px",
    top: "70px",
    left: "80px",
    "z-index": "-2",
  },
    
  pdfimg2: {
    position: "absolute",
    width: "275px",
    "max-height": "165px",
    left: "795px",
    top: "250px",
    "z-index": "-1",
  },
    
  pdfimg3: {
    position: "absolute",
    width: "250px",
    "max-height": "150px",
    left: "850px",
    top: "440px",
    "z-index": "-1",
  },
  pdfdescriptifMarketingTitle: {
    position: "absolute",
    top: "540px",
    left: "200px",
    width: "100px",
    "text-align": "end",
  },
  pdfdescriptifTechniqueTitle: {
    position: "absolute",
    top: "680px",
    left: "175px",
    width: "125px",
    "text-align": "end",
  },
  pdfdescriptifMarketingContentContainer: {
    "font-family": "Futura Medium",
    position: "absolute",
    top: "515px",
    left: "350px",
    width: "234px",
    height: "90px",
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
  },
  pdfdescriptifMarketingContent: {
    "font-size": "10px",
    flex: 1,
  },
  pdfdescriptifTechniqueContentContainer: {
    position: "absolute",
    top: "630px",
    left: "350px",
    width: "245px",
    height: "140px",
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
  },
  pdfdescriptifTechniqueContent: {
    "font-family": "Futura Medium",
    "font-size": "10px",
    flex: 1
  },
  pdfvariantsTableContainer: {
    position: "absolute",
    top: "615px",
    right: "25px",
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
    width: "450px"
  },
  pdfvariantsTable: {
    "font-family": "Futura Medium",
    "font-size": "9px"
  },
  pdfrowStyle: {
    "background-color": "rgb(240, 240, 240)"
  },
  pdftableHeaderLeft: {
    "text-align": "start",
    width: "110px"
  },
  pdfcolumnStyle: {
    "text-align": "center",
  },
  marginTopPrint: {
    height:100
  }
  
}


export default PDFRendering