import "./rendering.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const axios = require("axios").default;

async function handleDeclinaisonWebDownload(event, excelData) {
  axios
    .post("/api/downloadDeclinaisonMatriceWeb", excelData, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Matrice Web.xlsx";
      a.click();
      a.remove();
    })
    .catch((error) => {
      console.log(error);
    });
}

async function handleDeclinaisonRetailDownload(event, excelData) {
  axios
    .post("/api/downloadDeclinaisonMatriceRetail", excelData, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Matrice Retail Produit.xlsx";
      a.click();
      a.remove();
    })
    .catch((error) => {
      console.log(error);
    });
}

async function handleBundleRetailDownload(event, excelData) {
  axios
    .post("/api/downloadBundleMatriceRetail", excelData, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Matrice Retail Bundle.xlsx";
      a.click();
      a.remove();
    })
    .catch((error) => {
      console.log(error);
    });
}

export default function ExcelRendering() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [documentType, setDocumentType] = useState(null) 
  const search = useLocation().search;

  useEffect(() => {
    const currentIds = new URLSearchParams(search).get("ids");
    const currentToken = new URLSearchParams(search).get("token");
    const currentLocale = new URLSearchParams(search).get("locale");
    const currentDocumentType = new URLSearchParams(search).get("documentType")
    const today = new Date().toLocaleString("fr-FR");

    document.title = "Sélection Produits Sourcidys " + today;

    axios
      .get("/api/excel", {
        params: {
          token: encodeURIComponent(currentToken),
          ids: currentIds,
          locale: currentLocale,
          documentType: currentDocumentType
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setExcelData(res.data);
          setIsLoaded(true);
          setDocumentType(currentDocumentType)
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  }, []);

  if (isLoaded) {
    if (excelData != null) {
      if (documentType === 'declinaison'){
        return (
          <div className="homecontainer">
            <div className="homebuttonsContainer">
              <button
                className="blueButton"
                onClick={(event) => handleDeclinaisonWebDownload(event, excelData)}
              >
                Télécharger Matrice Web
              </button>
            </div>
            <div className="homebuttonsContainer">
              <button
                className="redButton"
                onClick={(event) => handleDeclinaisonRetailDownload(event, excelData)}
              >
                Télécharger Matrice Retail
              </button>
            </div>
          </div>
        )
      } else if (documentType === 'bundle') {
        return (
          <div className="homecontainer">
            <div className="homebuttonsContainer">
              <button
                className="redButton"
                onClick={(event) => handleBundleRetailDownload(event, excelData)}
              >
                Télécharger Matrice Retail
              </button>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className="homeContainer">
          <div className="homebuttonsContainer">
            Une erreur est survenue pendant le téléchargement des données
            <br />
            Veuillez réessayer
            <br />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="homecontainer">
        <div className="homebuttonsContainer">
          Récupération des données...
          <br />
          Veuillez Patienter
          <br />
          Le temps peut varier selon le nombre de produits.
        </div>
      </div>
    );
  }
}
