

function ErrorPage() {
	return ( 
		<p>An error occured</p>
	)
}



export default ErrorPage